import DatePicker from "./datePicker";
import DatePickerRange from "./datePickerRange";
import DateTimepicker from "./dateTimepicker";
import Calendar from "./calendar";
import RangeCalendar from "./rangeCalendar";

DatePicker.DatePickerRange = DatePickerRange;
DatePicker.DateTimepicker = DateTimepicker;

export { Calendar, RangeCalendar };

export default DatePicker;
