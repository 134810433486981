import axios from "axios";
import { BACKEND_BASE_URL } from "./baseurl";
const BACKEND_URL = BACKEND_BASE_URL;
const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async function (config) {
    if (config.url !== "admin/admin-sign-in") {
      const admin = localStorage.getItem("admin");
      const authData = JSON.parse(admin).auth;
      const token = JSON.parse(authData).session.token;

      config.headers = {
        Authorization: `${token}`,
        Accept: "application/json",
        "Content-Type": "multipart/form-data;",
      };
    }

    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  async function (response) {
    // Do something with response data
    const result = response?.data;
    return result;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);
export default axiosInstance;
